import type { PlatformControllerFlowAPI, ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { PanoramaClient } from '@wix/yoshi-flow-editor/build/cjs/exports/panorama';
import { toError, is403, isNetworkError } from './errors';

type Predicate = (e: unknown, flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI) => boolean;
type Options = Parameters<ReturnType<PanoramaClient['errorMonitor']>['reportError']>;
type ExtendedOptions = Options[1] & { additionalIgnorePredicates?: Predicate[] };

const isEditor403: Predicate = (e, flowAPI) => flowAPI.environment.isEditor && is403(e);
const DEFAULT_IGNORED_ERROR_PREDICATES: Predicate[] = [isNetworkError, isEditor403];

export function captureViewerException(
  flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI,
  error: unknown,
  extendedOptions: ExtendedOptions = {},
) {
  const { additionalIgnorePredicates = [], ...options } = extendedOptions;
  const isErrorIgnored = [...additionalIgnorePredicates, ...DEFAULT_IGNORED_ERROR_PREDICATES].some((isIgnored) =>
    isIgnored(error, flowAPI),
  );
  if (!isErrorIgnored) {
    flowAPI.panoramaClient?.errorMonitor().reportError(toError(error), options);
  }
}

import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { plansPageView } from '@wix/bi-logger-pricing-plans-data/v2';
import { PlanWidgetRole, SinglePlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { Analytics } from '../../services/analytics';
import { WarmupData } from '../../services/WarmupData';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { retry401 } from '../../utils/retry401';
import { captureViewerException } from '../../utils/viewer-errors';
import { CtaClickHandler } from '../Plan/viewer.controller';
import model from './model';

const getDemoPlan = (t: EditorScriptFlowAPI['translations']['t']): PublicPlan => ({
  name: t('blocks.demo-plan.name'),
  description: t('blocks.demo-plan.description'),
  pricing: {
    price: {
      value: '15',
      currency: 'USD',
    },
    subscription: {
      cycleCount: 3,
      cycleDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
  },
  perks: {
    values: [t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk')],
  },
});

export default model.createController(({ $w, $widget, flowAPI, controllerConfig }) => {
  let autoLoadPlan: boolean = true;

  const warmUpData = new WarmupData(controllerConfig.compId, controllerConfig.wixCodeApi, flowAPI);
  const getDemoPlanIfEditor = () => {
    return flowAPI.environment.isEditor ? getDemoPlan(flowAPI.translations.t) : null;
  };

  const fetchPlan = async (planId?: string): Promise<PublicPlan | null> => {
    if (!planId) {
      return getDemoPlanIfEditor();
    }
    const cacheKey = `single-plan-data-${planId}`;
    const response = await warmUpData.cache(cacheKey, () =>
      flowAPI.httpClient.request(listPublicPlans({ planIds: [planId] })),
    );

    return response.data.plans?.[0] ?? getDemoPlanIfEditor();
  };

  const setPlan = async (planId: string) => {
    flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetPlanData).start();
    if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
      showSpinner();
    }

    try {
      const plan = await retry401(flowAPI.controllerConfig.wixCodeApi, () => fetchPlan(planId));
      if (!plan) {
        showEmptyState();
      } else {
        $w(`#${PlanWidgetRole.PlanWidget}`).setPlan(plan);
        await showLoadedState();
        await $w(`#${PlanWidgetRole.PlanWidget}`).registerCtaHandler(plan, 'single');
        const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
        analytics.addProductImpression([plan]);
        flowAPI.bi?.report(plansPageView({ widgetType: 'single_blocks' }));
      }
      flowAPI.panoramaClient?.transaction(SinglePlanInteractions.SetPlanData).finish();
    } catch (e) {
      captureViewerException(flowAPI, e);
      showEmptyState();
    }
  };

  const showSpinner = () => {
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateSpinner);
  };

  const showEmptyState = () => {
    $w(`#${PlanWidgetRole.EmptyStateTitle}`).text = flowAPI.translations.t('blocks.single-plan.empty.title');
    $w(`#${PlanWidgetRole.EmptyStateText}`).text = flowAPI.translations.t('blocks.single-plan.empty.text');
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateEmpty);
  };

  const showLoadedState = () => {
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateLoaded);
  };

  const getIsInitialLoadWarmupKey = (planId: string) => `single-plan-initial-load-${planId}`;

  const setIsInitialLoad = (planId: string) => {
    const key = getIsInitialLoadWarmupKey(planId);
    warmUpData.set(key, true);
  };

  const getIsInitialLoad = (planId: string) => {
    const key = getIsInitialLoadWarmupKey(planId);
    return Boolean(warmUpData.get(key));
  };

  $widget.onPropsChanged(async (oldProps, newProps) => {
    const plan = await fetchPlan(newProps.planId);
    $w(`#${PlanWidgetRole.PlanWidget}`).setPlan(plan);
    $w(`#${PlanWidgetRole.PlanWidget}`).registerCtaHandler(plan, 'single');
  });

  return {
    pageReady: async () => {
      if (!autoLoadPlan) {
        return;
      }
      // Viewer controller can be executed without widget being rendered
      // This happens in editor, when page has an OOI widget, but not blocks widget
      const isWidgetRendered = $w(`#${PlanWidgetRole.PlanWidget}`).setPlan !== undefined;
      if (isWidgetRendered) {
        const planId = $widget.props.planId;
        if (flowAPI.environment.isSSR) {
          setIsInitialLoad(planId);
          await setPlan(planId);
        } else if (getIsInitialLoad(planId)) {
          await setPlan(planId);
        } else {
          // Do not await for setPlan if page was visited by navigation and not by directly visiting the site,
          // so pageReady resolves faster and doesn't slow down the navigation
          setPlan($widget.props.planId);
        }
      } else {
        console.error('Single Plan Widget does not exist in page');
      }
    },
    exports: {
      onSelect: (cb: CtaClickHandler) => {
        $w(`#${PlanWidgetRole.PlanWidget}`).onSelect(cb);
      },
      setPlan: (planId: string) => {
        autoLoadPlan = false;
        setPlan(planId);
      },
    },
  };
});

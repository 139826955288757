import { ControllerFlowAPI, ControllerParams, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { toError } from '../utils/errors';

export class WarmupData {
  constructor(
    protected keyPrefix: string,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI,
  ) {}

  async cache<T>(path: string, fetcher: () => Promise<T>): Promise<T> {
    const key = this.keyPrefix + path;
    if (this.flowAPI.environment.isSSR) {
      const data = await fetcher();
      this.wixCodeApi.window.warmupData.set(key, JSON.stringify(data));
      return data;
    } else {
      try {
        const data = this.wixCodeApi.window.warmupData.get(key);
        if (data) {
          return JSON.parse(data);
        }
      } catch (e) {
        this.flowAPI.panoramaClient?.errorMonitor().reportError(toError(e));
      }
      return fetcher();
    }
  }

  set(path: string, value: unknown) {
    const key = this.keyPrefix + path;
    this.wixCodeApi.window.warmupData.set(key, JSON.stringify(value));
  }

  get<T = any>(path: string): T | null {
    try {
      const key = this.keyPrefix + path;
      const data = this.wixCodeApi.window.warmupData.get(key);
      if (data) {
        return JSON.parse(data);
      } else {
        return null;
      }
    } catch (e) {
      this.flowAPI.panoramaClient?.errorMonitor().reportError(toError(e));
      return null;
    }
  }
}

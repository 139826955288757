import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import {
  CheckoutData,
  encodeBase64Url,
  IntegrationData,
  NavigateToSectionProps,
  integrationDataFromQuery,
  extractIntegrationData,
  parseAppSectionParams,
  integrationDataToPricingPageOptions,
  integrationDataToQuery,
  integrationDataToAppSectionParams,
} from '@wix/pricing-plans-router-utils';
import type {
  PricingPlansPublicAPI,
  NavigateToPackagePickerOptions,
  NavigateToCheckoutOptions,
  PricingPageOptions,
  TranslatedPricingDetails,
} from '@wix/pricing-plans-tpa-api';
import { getPlanPrice } from '@wix/pricing-plans-utils';
import { navigateToHeadlessIfNeeded, getHeadlessUrl, NavigationType } from '@wix/wix-to-headless-redirect-client';
import type { ViewerScriptFlowAPI, InitAppForPageFn, TFunction } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID as appDefinitionId } from './constants';
import { getFormattedPriceData } from './hooks';
import type { SubPage } from './types/common';
import { resolveLocale } from './utils';
import {
  allowCustomStartDate,
  createEcomCheckout,
  fetchPlanV3,
  isViewerOnEcom,
  navigateToEcomCheckout,
} from './utils/ecom';
import { getFormattedAdditionalFees } from './utils/get-formatted-additional-fees';
import { getFreeTrialDaysLabel } from './utils/getFreeTrialDaysLabel';
import { getPeriodLabel } from './utils/getPeriodLabel';
import { getPlanDuration } from './utils/getPlanValidityCycle';
import { isMultiPageApp } from './utils/isMultiPageApp';
import { getSectionId, getSubPagePath } from './utils/navigation';

type WixCodeApi = Parameters<InitAppForPageFn>[2];

let flowAPI: ViewerScriptFlowAPI;
let wixCodeApi: WixCodeApi;
let isMPA = false;
let isEcomEnabled = false;

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  _wixCodeApi,
  platformServicesApis,
  _flowAPI,
) => {
  flowAPI = _flowAPI;
  wixCodeApi = _wixCodeApi;
  isMPA = await isMultiPageApp(wixCodeApi, initParams);

  isEcomEnabled = _flowAPI.experiments.enabled(TPA_EXPERIMENTS.USE_SERVERLESS_FOR_EIE)
    ? await isViewerOnEcom(_flowAPI.httpClient, wixCodeApi, initParams)
    : _flowAPI.experiments.enabled(TPA_EXPERIMENTS.ECOM_INTEGRATION_ENABLED);
};

export const exports = async (): Promise<PricingPlansPublicAPI> => {
  const { relativeUrl } = await wixCodeApi.site.getSectionUrl({
    sectionId: 'membership_plan_picker_tpa',
    appDefinitionId,
  });
  if (!relativeUrl) {
    console.warn('Pricing Plans navigation API will not work, because Pricing Plans section is not installed.');
  }

  async function navigateToPackagePicker(options: NavigateToPackagePickerOptions) {
    const runNativeNavigation = () => {
      const integrationData = buildIntegrationData(options);
      if (!relativeUrl) {
        console.error('Cannot navigate to pricing page, because it is not available on the site.');
      } else {
        wixCodeApi.location.to!(
          relativeUrl +
            '?' +
            (isMPA ? integrationDataToQuery(integrationData) : integrationDataToAppSectionParams(integrationData)),
        );
      }
    };

    navigateToHeadlessIfNeeded({
      navParams: {
        logicalName: NavigationType.PAID_PLANS,
        params: {
          planIds: options?.planIds,
          checkoutData: buildIntegrationData(options),
        },
      },
      location: wixCodeApi.location,
      fallbackNavigation: runNativeNavigation,
    });
  }

  async function getPricingPageUrl(options: NavigateToPackagePickerOptions) {
    const headlessUrl = getHeadlessUrl({
      navParams: {
        logicalName: NavigationType.PAID_PLANS,
        params: {
          planIds: options?.planIds,
          checkoutData: buildIntegrationData(options),
        },
      },
      baseUrl: wixCodeApi.location.baseUrl,
      query: wixCodeApi.location.query,
    });

    if (headlessUrl) {
      return headlessUrl;
    }

    const integrationData = buildIntegrationData(options);
    if (relativeUrl) {
      return (
        relativeUrl +
        '?' +
        (isMPA ? integrationDataToQuery(integrationData) : integrationDataToAppSectionParams(integrationData))
      );
    } else {
      console.error('Cannot get pricing page url, because it is not available on the site.');
    }
  }

  function getPricingPageOptions(): PricingPageOptions {
    const { query } = wixCodeApi.location;
    const { appSectionParams } = query;

    if (appSectionParams) {
      const params = parseAppSectionParams(appSectionParams);
      const { integrationData, decodingErrors } = extractIntegrationData(params);

      if (decodingErrors) {
        flowAPI.panoramaClient?.errorMonitor().reportError(new Error('Unable to decode integration data'), {
          context: { integrationData: params, site: { url: wixCodeApi.location.url }, decodingErrors },
        });
      }

      return integrationDataToPricingPageOptions(integrationData);
    }

    return integrationDataToPricingPageOptions(integrationDataFromQuery(new URLSearchParams(query)));
  }

  async function navigateToCheckout(options: NavigateToCheckoutOptions) {
    const { planId, biOptions, ...checkout } = options;
    const integrationData = buildIntegrationData({ planIds: [planId], checkout, biOptions });
    if (isEcomEnabled) {
      const planV3 = await fetchPlanV3({ id: planId }, flowAPI.httpClient);
      const shouldSkipPlanCustomizationPage = !(planV3.formId || allowCustomStartDate(planV3));
      if (shouldSkipPlanCustomizationPage) {
        const ecomCheckout = await createEcomCheckout({
          flowAPI,
          plan: planV3,
          integrationData,
        });
        return navigateToEcomCheckout({ checkoutId: ecomCheckout.id!, wixCodeApi, integrationData, planId });
      }
    }

    if (isMPA) {
      const page: SubPage = isEcomEnabled
        ? {
            name: 'customization',
            planCustomizationData: {
              integrationData,
              planId,
            },
          }
        : {
            name: 'checkout',
            checkoutData: {
              integrationData,
              planId,
            },
          };

      const sectionId = getSectionId(page.name, { isMultiPageApp: isMPA });
      const path = getSubPagePath(page, { isMultiPageApp: isMPA });
      const sectionUrl = await wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
      if (!sectionUrl.relativeUrl) {
        console.error(
          `Cannot navigate to checkout, because Pricing Plans ${
            isEcomEnabled ? 'Customization' : 'Checkout'
          } page is not available on the site.`,
        );
      } else {
        const currentUrl = '/' + wixCodeApi.location.path.join('/');
        const disableScrollToTop = currentUrl.startsWith(sectionUrl.relativeUrl!);
        return wixCodeApi.location.to!(`${sectionUrl.relativeUrl ?? ''}${path}`, { disableScrollToTop });
      }
    }

    if (!relativeUrl) {
      console.error('Cannot navigate to checkout, because Pricing Plans page is not available on the site.');
    } else {
      const currentUrl = '/' + wixCodeApi.location.path.join('/');
      const disableScrollToTop = currentUrl.startsWith(relativeUrl);

      wixCodeApi.location.to!(
        relativeUrl +
          '/payment/' +
          encodeBase64Url({
            integrationData: buildIntegrationData({
              planIds: [planId],
              checkout,
              biOptions,
            }),
            planId,
          } as CheckoutData),
        {
          disableScrollToTop,
        },
      );
    }
  }

  async function getCheckoutUrl(options: NavigateToCheckoutOptions) {
    const { planId, biOptions, ...checkout } = options;

    if (isMPA) {
      const page: SubPage = isEcomEnabled
        ? {
            name: 'customization',
            planCustomizationData: {
              integrationData: buildIntegrationData({ planIds: [planId], checkout, biOptions }),
              planId,
            },
          }
        : {
            name: 'checkout',
            checkoutData: {
              integrationData: buildIntegrationData({ planIds: [planId], checkout, biOptions }),
              planId,
            },
          };
      const sectionId = getSectionId(page.name, { isMultiPageApp: isMPA });
      const path = getSubPagePath(page, { isMultiPageApp: isMPA });
      const sectionUrl = await wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
      if (!sectionUrl.relativeUrl) {
        console.error(
          `Cannot get Checkout URL, because Pricing Plans ${
            isEcomEnabled ? 'Customization' : 'Checkout'
          } page is not available on the site.`,
        );
      } else {
        return `${sectionUrl.relativeUrl ?? ''}${path}`;
      }
    }

    if (!relativeUrl) {
      console.error('Cannot get Checkout URL, because Pricing Plans page is not available on the site.');
    } else {
      return (
        relativeUrl +
        '/payment/' +
        encodeBase64Url({
          integrationData: buildIntegrationData({
            planIds: [planId],
            checkout,
            biOptions,
          }),
          planId,
        } as CheckoutData)
      );
    }
  }

  return {
    getTranslatedPricingDetails(plan: PublicPlan): TranslatedPricingDetails {
      const t = flowAPI.translations.t as TFunction;
      const { value, currency } = getPlanPrice(plan);
      const locale = resolveLocale(wixCodeApi);

      const formattedPriceData = getFormattedPriceData({
        createCurrencyFormatter: flowAPI.getCurrencyFormatter,
        locale,
        value,
        currency,
      });

      return {
        price: formattedPriceData.fullPrice,
        cycle: getPeriodLabel(plan.pricing?.subscription, t) ?? '',
        duration: getPlanDuration(plan, t),
        freeTrial: getFreeTrialDaysLabel(plan, t),
        additionalFees: getFormattedAdditionalFees({
          plan,
          locale,
          createCurrencyFormatter: flowAPI.getCurrencyFormatter,
        }),
      };
    },
    getPricingPageOptions,
    navigateToPackagePicker,
    navigateToPricingPage: navigateToPackagePicker,
    getPricingPageUrl,
    navigateToCheckout,
    navigateToPlanPage: navigateToCheckout,
    getCheckoutUrl,
    getPlanPageUrl: getCheckoutUrl,
  };
};

const buildIntegrationData = (options: NavigateToPackagePickerOptions): IntegrationData => {
  const data: IntegrationData = {
    planIds: options?.planIds,
    title: options?.title,
    subtitle: options?.subtitle,
    minStartDate: options?.checkout?.minStartDate,
    maxStartDate: options?.checkout?.maxStartDate,
  };
  // successStatus is deprecated, thankyouPage should be used now.
  if (options?.checkout?.successStatus?.content) {
    data.verticalStatusContent = {
      titleText: options.checkout.successStatus.content.title,
      contentText: options.checkout.successStatus.content.message,
      buttonText: options.checkout.successStatus.content.cta,
    };
  }
  if (options?.checkout?.successStatus?.navigation?.type === 'url') {
    data.navigateTo = options.checkout.successStatus.navigation.url;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'page') {
    data.navigateToPageProps = options.checkout.successStatus.navigation.pageId;
  } else if (options?.checkout?.successStatus?.navigation?.type === 'section') {
    data.navigateToSectionProps = options.checkout.successStatus.navigation.options as NavigateToSectionProps;
  }

  if (options?.checkout?.thankYouPage?.content) {
    data.verticalStatusContent = {
      titleText: options.checkout.thankYouPage.content.title,
      contentText: options.checkout.thankYouPage.content.message,
      buttonText: options.checkout.thankYouPage.content.cta,
    };
  }
  if (options?.checkout?.thankYouPage?.navigation?.type === 'url') {
    data.navigateTo = options.checkout.thankYouPage.navigation.url;
  } else if (options?.checkout?.thankYouPage?.navigation?.type === 'page') {
    data.navigateToPageProps = options.checkout.thankYouPage.navigation.pageId;
  } else if (options?.checkout?.thankYouPage?.navigation?.type === 'section') {
    data.navigateToSectionProps = options.checkout.thankYouPage.navigation.options as NavigateToSectionProps;
  }

  if (options?.biOptions) {
    data.biOptions = {
      referralInfo: options.biOptions.referralInfo,
      referralId: options.biOptions.referralId,
    };
  }
  return data;
};
